/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router';
const leozinhoHome = () => import("@/pages-festaclick/leozinhoHome.vue");
const giovanaHome = () => import("@/pages-festaclick/giovanaHome.vue");
const routes = [
  {
    path: '/evento/giovana15',
    name: 'Category List',
    component: giovanaHome,
  },
  {
    path: '/evento/leozinho1',
    name: 'Category List',
    component: leozinhoHome,
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: leozinhoHome 
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  next();
})

export default router
