<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {

    }
  },

  created() {

  }
};

</script>
<style>
.tg-badge {
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "AvenirNext", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #393a3d;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 11.5px;
  border-style: solid;
  border-width: 1px;
  line-height: 13px;
  padding: 4px 14px;
  max-width: 100%;
}

.tg-badge-warning {
  color: #f95700;
  background-color: #ffffff;
  border-color: #f95700;
}

.tg-badge-purple {
  color: #7b0c97;
  background-color: #ffffff;
  border-color: #7b0c97;
}

.tg-badge-error {
  color: #c30000;
  background-color: #ffffff;
  border-color: #c30000;
}

.tg-badge-success {
  color: #2ca01c;
  background-color: #ffffff;
  border-color: #2ca01c;
}

.tg-badge-pink {
  color: #c9007a;
  background-color: #ffffff;
  border-color: #c9007a;
}

.tg-badge-info {
  color: #0077c5;
  background-color: #ffffff;
  border-color: #0077c5;
}

.panel-active {
  transform: scale(1.13);
}

.blur {
  filter: blur(3px) !important;
}

.btn-lg {
  border-radius: 0 !important;
  font-weight: 600;
}

.btn-outline-dark {
  border-radius: 0 !important;
  font-weight: 600;

}

.highcharts-credits {
  display: none;
}

.btn-dropdown-sb {
  border-color: #4e4b4b !important;
  border-width: 0.5px !important;
  color: #4e4b4b !important;
  width: 10% !important;
}

.text-success2 {
  color: #2ca01c !important;
}

.btn-info {
  border-radius: 0 !important;
  background-color: #0077c5 !important;
  color: #fff !important;
}

.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: ' ';
  visibility: hidden;
}

.ellipsis span {
  position: absolute;
  left: 0;
  margin-left: 1em;
  right: 0;
  color: #515151;
  font-size: 14px;
  margin-top: -0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-outline-info {
  border-radius: 0 !important;
  --bs-btn-color: #0077c5 !important;
  --bs-btn-border-color: #0077c5 !important;
  --bs-btn-hover-bg: #0077c5 !important;
  --bs-btn-hover-border-color: #0077c5 !important;
}

.btn-outline-success {
  border-radius: 0 !important;
  --bs-btn-color: #2ca01c !important;
  --bs-btn-border-color: #2ca01c !important;
  --bs-btn-hover-bg: #269217 !important;
  --bs-btn-hover-border-color: #269217 !important;
}

.btn-dark {
  border-radius: 0 !important;
  --bs-btn-bg: #404040 !important;
  --bs-btn-border-color: #000000 !important;
  --bs-btn-hover-bg: #272727 !important;
  --bs-btn-active-bg: #2ca38383801c !important;
}

.btn-outline-danger {
  border-radius: 0 !important;
  --bs-btn-color: #d41515 !important;
  --bs-btn-border-color: #d41515 !important;
  --bs-btn-hover-bg: #d41515 !important;
  --bs-btn-hover-border-color: #d41515 !important;
}

.btn-success {
  border-radius: 0 !important;
  --bs-btn-bg: #2ca01c !important;
  --bs-btn-border-color: #2ca01c !important;
  --bs-btn-hover-bg: #269217 !important;
  --bs-btn-active-bg: #2ca01c !important;
}

.form-error {
  border-color: #d41515 !important;
  border-width: 0.2px !important;
  background-color: #ffb1b170 !important;
}

body {
  background-color: #f1f1f1 !important;
  /* background-color: #f4f5f8!important; !*/
}

.text-muted2 {
  color: #33333346;
}

.popper-dark {

  --popper-theme-background-color: #4f4f4f;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: white;
  --popper-theme-font-size: 1px;
  --popper-theme-border-width: 0px;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 8px;

  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.btn-outline-sb1 {
  border-color: #ff9200 !important;
  color: rgb(191, 109, 1) !important;
}

.btn-sb1 {
  background-color: #ff9200 !important;
  color: #fff !important;
  border-color: #ffb350 !important;
}

.btn-sb1:hover {
  background-color: #fcb252 !important;
}

.vas-menu.dark-theme .label.miniActive .labelName,
.vas-menu.dark-theme .label.miniActive .menu-icon {
  color: rgb(255 168 0) !important;
  font-weight: bolder;
}

.vas-menu.dark-theme .label.activeClass .menu-icon {
  color: rgb(216, 130, 17) !important;
}

.vas-menu.dark-theme .label.activeClass {
  color: rgb(191, 109, 1) !important;
}


.text-SB1 {
  color: #ff9200 !important;
}

.text-SB1 {
  color: #ff9200 !important;
}

@import '@vueform/multiselect/themes/default.css';

.multiselect-option {
  line-height: 0.5rem !important;
  white-space: nowrap !important;
}

.multiselect-option.is-selected {
  background: #858585 !important;
}

.clickable:hover {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.div-table-break {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  border-right: 0;
  min-width: 90%;
  max-width: 90%;
  font-size: 14px;
}

.table> :not(caption)>*>* {
  padding: 0.3rem 0.75rem !important;
}


.p-inline-message {
  font-size: 12px;

}

.a-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.p-dropdown-label {
  padding: 9px !important;
  font-size: 13px !important;
}

.p-dropdown-filter {
  padding: 5px !important;
  font-size: 10px !important;
}

.p-dropdown-item {
  padding: 0.25rem 1.25rem;
}

.p-dropdown-panel {
  z-index: 2000 !important;
  font-size: 13px;
}

.p-multiselect-panel {
  z-index: 2000 !important;
  font-size: 13px;
}

.p-icon-spin {
  -webkit-animation: p-icon-spin 2s infinite linear;
  animation: p-icon-spin 2s infinite linear;
}
</style>
